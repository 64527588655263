import * as React from 'react';
import classes from './kpi-box-popover.module.scss';
import {exists} from 'front-core';
import {HtmlTooltip, TrendChip} from 'ui-components';
import {HomepageMetricViewer} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-metric-viewer/homepage-metric-viewer.component';
import {AnomalyMode} from '../../../../../../objects/models/homepage.model';
import {
  ModelSample,
  ModelSeriesGranularity,
  SeriesDatetimeAttribute,
} from '../../../../../../objects/models/model-sample-series.model';
import {useContext, useMemo} from 'react';
import {getSampleWoWChange} from '../../../../../homepage/components/homepage-summary/homepage-summary.utils';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {formatMetricValue} from '../../kpi-tree.utils';
import {KPITreeContext} from '../../kpi-tree.context';

interface OwnProps {
  name: string;
  metricId: number;
  isPercentageValue: boolean;
  granularity: ModelSeriesGranularity;
  lastSample?: ModelSample;
  previousSample?: ModelSample;
  datetimeAttribute?: SeriesDatetimeAttribute;
  children: any;
}

type AllProps = OwnProps;

export const KPIBoxPopover: React.FC<AllProps> = (props: AllProps) => {
  const {
    metricId,
    name,
    granularity,
    datetimeAttribute,
    lastSample,
    previousSample,
    isPercentageValue,
    children,
  } = props;
  const {toDate} = useContext(KPITreeContext);
  const {userHomepageId} = useProductData();
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, previousSample),
    [lastSample, previousSample]
  );

  return (
    <HtmlTooltip
      placement={'top'}
      interactive
      title={
        <div className={classes.KPIPopover}>
          <div className={classes.Header}>
            <div className={classes.Title}>{name}</div>
            <div className={classes.Stats}>
              <div className={classes.Item}>
                <span className={classes.Label}>Last {granularity}</span>
                <span className={classes.Value}>
                  {exists(lastSample?.value)
                    ? formatMetricValue(lastSample.value, isPercentageValue)
                    : '-'}
                </span>
              </div>
              <div className={classes.Item}>
                <span className={classes.Label}>
                  Previous{granularity === 'day' ? ' week  ' : ' '}
                  {granularity}
                </span>
                <span className={classes.Value}>
                  {exists(previousSample?.value)
                    ? formatMetricValue(previousSample.value, isPercentageValue)
                    : '-'}
                </span>
              </div>
              {trend && (
                <div className={classes.Item}>
                  <span className={classes.Label}>Change</span>
                  <span className={classes.Value}>
                    <TrendChip
                      value={trend.value}
                      size={'xsmall'}
                      isSignificant={trend.isSignificant}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={classes.MetricViewerWrapper}>
            <HomepageMetricViewer
              className={classes.MetricViewer}
              metricId={metricId}
              homepageId={userHomepageId}
              configuration={{
                anomalyMode: AnomalyMode.LOOPS_ALGO,
                anomalyThreshold: 0.1,
              }}
              showHeader={false}
              showOptions={false}
              showLegend={false}
              toDate={toDate}
              datetimeAttribute={datetimeAttribute}
            />
          </div>
        </div>
      }
    >
      {children}
    </HtmlTooltip>
  );
};
