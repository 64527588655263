import {NRC} from './index';
import {RequestType, toCamelCase} from 'front-core';

import {GetMetricTreeFiltersDTO, InputMetricDTO} from '../objects/dto/metric-tree.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const getMetricTreeNetworkRequest: NRC<void, GetMetricTreeFiltersDTO> = (
  metricId: number,
  query: GetMetricTreeFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-tree/${metricId}`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const addInputMetricNetworkRequest: NRC<void, void> = (data: InputMetricDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/${data.metricId}/input/${data.inputMetricId}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeInputMetricNetworkRequest: NRC<void, void> = (data: InputMetricDTO) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/metric-tree/${data.metricId}/input/${data.inputMetricId}`,
  responseTransformer: res => toCamelCase(res.data),
});
