import {coreStore} from './core/core.store';
import {interfaceStore} from './interface/interface.store';
import {authStore} from './auth/auth.store';
import {remoteListsStore} from './remote-lists/remote-lists.store';
import {selectedStore} from './selected/selected.store';
import {userOnboardingStore} from './user-onboarding/user-onboarding.store';
import {notificationsStore} from './notifications/notifications.store';
import * as coreEpics from './core/core.epics';
import * as authEpics from './auth/auth.epics';
import * as funnelsEpics from './funnels/funnels.epics';
import * as experimentsEpics from './experiments/experiments.epics';
import * as interfaceEpics from './interface/interface.epics';
import * as remoteListsEpics from './remote-lists/remote-lists.epics';
import * as selectedEpics from './selected/selected.epics';
import * as actionsListenerEpics from './actions-listener/actions-listener.epics';
import * as analysesEpics from './analyses/analyses.epics';
import * as goalEpics from './goals/goals.epics';
import * as annotationsEpics from './annotations/annotations.epics';
import * as userReactionsEpics from './user-reactions/user-reactions.epics';
import * as featuresEpics from './features/features.epics';
import * as segmentsEpics from './segments/segments.epics';
import * as metricsEpics from './metrics/metrics.epics';
import * as milestonesEpics from './milestones/milestones.epics';
import * as healthMonitorEpics from './health-monitor/health-monitor.epics';
import * as segmentCategoriesEpics from './segment-categories/segment-categories.epics';
import * as metricCategoriesEpics from './metric-categories/metric-categories.epics';
import * as feedbacksEpic from './feedbacks/feedbacks.epics';
import * as tablesEpic from './tables/tables.epics';
import * as userSettingsEpics from './settings/user-settings.epics';
import * as eventGroupsEpics from './event-groups/event-groups.epics';
import * as sourceTableEpics from './source-tables/source-tables.epics';
import * as notificationsConfigEpics from './notifications-config/notifications-config.epics';
import * as pushInsightsEpics from './push-insights/push-insights.epics';
import * as analysisFoldersEpics from './analysis-folders/analysis-folders.epics';
import * as contentEpics from './content/content.epics';
import * as usersEpics from './users/users.epics';
import * as followUpsEpics from './follow-up-actions/follow-up-actions.epics';
import * as userOnboardingEpics from './user-onboarding/user-onboarding.epics';
import * as modelSamplesEpics from './model-samples/model-samples.epics';
import * as homepageEpics from './homepage/homepage.epics';
import * as notificationsEpics from './notifications/notifications.epics';
import * as experimentAutomationsEpics from './experiment-automations/experiment-automations.epics';
import * as metricTreeEpics from './metric-tree/metric-tree.epics';
import {values} from 'lodash';
import {createEpicStore} from 'front-core';

const stores = [
  coreStore,
  interfaceStore,
  remoteListsStore,
  selectedStore,
  authStore,
  userOnboardingStore,
  notificationsStore,
];

const epics = [
  ...values(coreEpics),
  ...values(interfaceEpics),
  ...values(remoteListsEpics),
  ...values(selectedEpics),
  ...values(authEpics),
  ...values(experimentsEpics),
  ...values(actionsListenerEpics),
  ...values(analysesEpics),
  ...values(goalEpics),
  ...values(annotationsEpics),
  ...values(userReactionsEpics),
  ...values(funnelsEpics),
  ...values(featuresEpics),
  ...values(segmentsEpics),
  ...values(metricsEpics),
  ...values(milestonesEpics),
  ...values(healthMonitorEpics),
  ...values(segmentCategoriesEpics),
  ...values(feedbacksEpic),
  ...values(tablesEpic),
  ...values(userSettingsEpics),
  ...values(eventGroupsEpics),
  ...values(sourceTableEpics),
  ...values(notificationsConfigEpics),
  ...values(pushInsightsEpics),
  ...values(analysisFoldersEpics),
  ...values(contentEpics),
  ...values(usersEpics),
  ...values(followUpsEpics),
  ...values(userOnboardingEpics),
  ...values(metricCategoriesEpics),
  ...values(modelSamplesEpics),
  ...values(homepageEpics),
  ...values(notificationsEpics),
  ...values(experimentAutomationsEpics),
  ...values(metricTreeEpics),
];

export const store = createEpicStore(stores, epics);
