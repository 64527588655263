import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {KPIBox} from './kpi-box/kpi-box.component';
import {KPIEdge} from './kpi-edge/kpi-edge.component';
import {KPIGroup} from './kpi-group/kpi-group.component';

export enum NodeType {
  METRIC = 'KPIBox',
  METRIC_GROUP = 'KPIGroup',
}
export enum EdgeType {
  METRIC_CONNECTION = 'KPIEdge',
}

export const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;
export const KPI_NODE_WIDTH = 260;
export const KPI_NODE_HEIGHT = 80;
export const KPI_GROUP_NODE_WIDTH = 84;
export const KPI_GROUP_NODE_HEIGHT = 28;

export const nodeTypes = {
  [NodeType.METRIC]: KPIBox,
  [NodeType.METRIC_GROUP]: KPIGroup,
};

export const edgeTypes = {
  [EdgeType.METRIC_CONNECTION]: KPIEdge,
};
export const HANDLE_COLOR = 'rgba(197, 201, 219, 1)';
export const HANDLE_STYLE = {
  background: HANDLE_COLOR,
  height: '0.8rem',
  width: '0.8rem',
};

export const DISABLE_REACT_FLOW_INTERACTION_PROPS = {
  panOnDrag: false,
  nodesDraggable: false,
  nodesConnectable: false,
  nodesFocusable: false,
  elementsSelectable: false,
  zoomOnScroll: false,
  zoomOnPinch: false,
  zoomOnDoubleClick: false,
  preventScrolling: false,
};

export const HIDE_REACT_FLOW_ATTRIBUTION = {
  proOptions: {hideAttribution: true},
};
