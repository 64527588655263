import * as React from 'react';
import {useMemo} from 'react';
import {
  ModelSample,
  ModelSampleSeries,
} from '../../../../../../../../objects/models/model-sample-series.model';
import moment from 'moment';
import TransKeys from 'translations';
import {ANNOTATION_ICONS} from '../../../../../../../../constants/ui';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../../../../../core/hooks/use-product-data.hook';
import {HomepageAnnotation} from '../../../../../../../../objects/models/homepage.model';
import {LineChart} from 'ui-components';
import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {ChartConfig} from '../../../../homepage-summary.types';
import {
  generateChartIdForSeriesSamples,
  transformSeriesToDatasets,
} from './homepage-metric-chart.utils';

export interface HomepageMetricChartSeries extends Omit<ModelSampleSeries, 'id'> {
  id?: number | string;
  name?: string;
}

interface OwnProps {
  series: HomepageMetricChartSeries[];
  reviewedSeriesId?: string | number;
  chartConfig: ChartConfig;
  confidenceIntervalConfig: ConfidenceIntervalConfig;
  metricName?: string;
  isPercentage?: boolean;
  entity?: string;
  goal?: number;
  xLabel?: string;
  hasCountEntities?: boolean;
  showLegend?: boolean;
  annotations?: HomepageAnnotation[];
  onAnnotationsClicked: (annotations: HomepageAnnotation[]) => void;
  onSampleClicked?: (sample: ModelSample) => void;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageMetricChart: React.FC<AllProps> = (props: AllProps) => {
  const {
    metricName,
    reviewedSeriesId,
    series,
    goal,
    isPercentage,
    entity,
    hasCountEntities,
    chartConfig,
    annotations,
    xLabel = 'date',
    onSampleClicked,
    onAnnotationsClicked,
    confidenceIntervalConfig,
    showLegend,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();

  const {firstPartialDate, lastPartialDate} = useMemo(() => {
    let firstPartialDate = null;
    let lastPartialDate = null;
    for (const s of series) {
      for (const sample of s.samples) {
        if (sample.isPartial) {
          firstPartialDate = firstPartialDate === null ? sample.datetime : firstPartialDate;
          lastPartialDate = sample.datetime;
        }
      }
    }
    return {firstPartialDate, lastPartialDate};
  }, [series]);
  const chartProps = useMemo(() => {
    const allDatasets = [];
    for (const s of series) {
      allDatasets.push(
        ...transformSeriesToDatasets(
          metricName,
          s,
          isPercentage,
          confidenceIntervalConfig,
          hasCountEntities,
          productEntitiesMap[entity].name
        )
      );
    }

    // const colors = ['#B8ADF9', '#9AB9F3'];
    const chartAnnotations = annotations.map(annotation => ({
      ...annotation,
      type: t(TransKeys.ANNOTATION.TYPE[annotation.type.toUpperCase()]),
      icon: ANNOTATION_ICONS[annotation.type],
      textColor: annotation.relatedExperimentId ? 'rgba(52, 131, 255, 1)' : undefined,
    }));

    const lines = [];
    if (goal && chartConfig.showTarget) {
      const goalValue = isPercentage ? Number((goal * 100).toFixed(2)) : goal;
      const goalValueText = isPercentage ? `${goalValue}%` : goalValue;

      lines.push({
        label: 'Target',
        title: (
          <span>
            {t(TransKeys.HOMEPAGE.LABELS.QUARTERLY_TARGET)}: <b>{goalValueText}</b>
          </span>
        ),
        description: '',
        dashed: true,
        position: goalValue,
        direction: 'horizontal',
      });
    }

    const granularity = series[0].granularity;
    const markAreas = [];
    if (firstPartialDate && lastPartialDate) {
      markAreas.push({
        from: moment
          .utc(firstPartialDate)
          .subtract(1, granularity)
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        to: lastPartialDate,
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      });
    }
    return {
      key: annotations.length,
      datasets: allDatasets,
      displayedDatasetIds: reviewedSeriesId
        ? [generateChartIdForSeriesSamples(reviewedSeriesId)]
        : [generateChartIdForSeriesSamples(series[0].id)],
      options: {
        annotations: chartConfig.showAnnotations ? chartAnnotations : undefined,
        lines,
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: granularity,
        },
        yLabelSuffix: isPercentage ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        errorBar: chartConfig.showCI,
        showQuarters: chartConfig.showQuarters,
        showHideCI: false,
        showHideAnnotation: false,
        xLabel: xLabel,
        // colors,
        markAreas: markAreas,
        showLegend,
      },
      pointTooltipCta: t(TransKeys.HOMEPAGE.METRIC_CHART.TOOLTIP_CTA),
      pointTooltipTitle: 'Recommended Action',
      onAnnotationClicked: onAnnotationsClicked ? onAnnotationsClicked : undefined,
      onDatasetPointClick: onSampleClicked
        ? pointData => onSampleClicked(pointData.point.metadata.sample)
        : undefined,
    } as any;
  }, [
    series,
    metricName,
    isPercentage,
    hasCountEntities,
    productEntitiesMap,
    onSampleClicked,
    xLabel,
    annotations,
    chartConfig,
    reviewedSeriesId,
    onAnnotationsClicked,
    confidenceIntervalConfig,
    firstPartialDate,
    lastPartialDate,
    entity,
    goal,
    showLegend,
    t,
  ]);

  return <LineChart {...chartProps} className={className} />;
};
