import {values} from 'lodash';
import classNames from 'classnames';
import classes from './funnel-page.module.scss';
import {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {StringParam, withDefault} from 'serialize-query-params';
import {AppRoutes, FUNNEL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import TransKeys from '../../../../constants/translation-keys';
import {getFunnelPageNetworkRequest} from '../../../../http/funnels.network-requests';
import {GenericLoading} from '../../../../modules/shared/components/general/generic-loading/generic-loading.component';
import {PageHeader} from '../../../../modules/shared/components/layout/page-header/page-header.component';
import PageLayout from '../../../../modules/shared/components/layout/page-layout';
import {ChartPyramidDuotoneIcon, useRemoteSourceStated} from 'ui-components';
import {useQueryParam} from 'use-query-params';
import {FunnelPageHeader} from '../../components/funnel-page-header/funnel-page-header.component';
import {HomepageFunnelViewer} from '../../../../modules/homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-funnel-viewer/homepage-funnel-viewer.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {FunnelPageTabs} from './funnel-page-tabs/funnel-page-tabs.component';
import {FunnelPageTab} from './funnel-page.types';
import {useDispatch} from 'react-redux';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {ModelKey} from '../../../../constants/model-key';
import {CoreActionsType} from '../../../../store/core/core.actions';

type FunnelPageComponentProps = {
  funnelId: number;
};

export const FUNNEL_PAGE_TAB_QUERY_PARAM = 'tab';

const FunnelPageComponent = (props: FunnelPageComponentProps) => {
  const {funnelId} = props;
  const {t} = useTranslation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [tab, setTab] = useQueryParam<string>(
    FUNNEL_PAGE_TAB_QUERY_PARAM,
    withDefault(StringParam, FunnelPageTab.RCA)
  );
  let {
    source: funnel,
    exec: getFunnel,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getFunnelPageNetworkRequest,
  });

  const {userHomepageId, userSettings} = useProductData();

  useEffect(() => {
    funnelId && getFunnel(funnelId);
  }, [funnelId, getFunnel]);

  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.FUNNEL && data.id === funnelId) {
        getFunnel(funnelId);
      }
    };

    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));

    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getFunnel, funnelId]);

  const tabs = useMemo(
    () =>
      values(FunnelPageTab).map(tab => ({
        key: tab,
        // change to appropriate funnel page key
        label: t(TransKeys.FUNNEL_PAGE.TABS[tab.toUpperCase()]),
      })),
    [t]
  );

  if (!funnel || isLoading) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PageHeader
        title={funnel.name}
        crumbs={[
          {
            name: 'Funnels',
            icon: <ChartPyramidDuotoneIcon />,
            navigateTo: AppRoutes.funnels(),
          },
        ]}
      />
      <PageLayout.Body noPadding isLoading={false}>
        <div className={classes.FunnelPage}>
          <div className={classes.Main}>
            <div className={classes.ContentSection}>
              <FunnelPageHeader funnel={funnel} />
              <HomepageFunnelViewer
                variant={'contained'}
                className={classes.GraphView}
                showHeader={false}
                funnelId={funnelId}
                homepageId={userHomepageId}
                configuration={userSettings}
              />
            </div>
            <div className={classes.TabsWrapper}>
              <div className={classes.Tabs} ref={tabsRef}>
                {tabs.map(t => (
                  <div
                    key={t.key}
                    onClick={() => setTab(t.key, 'replaceIn')}
                    className={classNames(classes.Tab, tab === t.key && classes.Selected)}
                  >
                    {t.label}
                    {t.key === FunnelPageTab.RCA && (
                      <div className={classes.DateSelection}>
                        <span className={classes.For}>for</span>
                        {/* <RCADatePicker */}
                        {/*   selected={rcaDate} */}
                        {/*   onChange={date => setRCADate(date, 'replaceIn')} */}
                        {/*   maxDate={metric.maxSampleDate} */}
                        {/*   granularity={granularity as any} */}
                        {/*   isLoading={isLoadingResult} */}
                        {/* /> */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={classNames(
                classes.ContentSection,
                tab !== FunnelPageTab.RCA && classes.MarginBottom
              )}
            >
              <FunnelPageTabs selectedTab={tab as FunnelPageTab} funnel={funnel} />
            </div>
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

type FunnelPagePathParams = {
  [FUNNEL_ID_PATH_PARAM]: string;
};

export const FunnelPage = (props: unknown) => {
  const {[FUNNEL_ID_PATH_PARAM]: funnelIdFromParams} = useParams<FunnelPagePathParams>();

  return (
    <FunnelPageComponent
      {...props}
      key={funnelIdFromParams}
      funnelId={Number(funnelIdFromParams)}
    />
  );
};
