import {createContext, useMemo} from 'react';
import * as React from 'react';
import {SeriesDatetimeAttribute} from '../../../../objects/models/model-sample-series.model';

export interface IKPITreeContext {
  toDate?: string;
  datetimeAttribute?: SeriesDatetimeAttribute;
  onAddInputKPI: (inputMetricId: number) => void;
  onRemoveInputKPI: (inputMetricId: number) => void;
  onKPIClicked: (metricId: number) => void;
  onViewKPIGroup: (parentId: number) => void;
}

export const KPITreeContext = createContext<IKPITreeContext>({
  toDate: undefined,
  datetimeAttribute: undefined,
  onAddInputKPI: undefined,
  onRemoveInputKPI: undefined,
  onKPIClicked: undefined,
  onViewKPIGroup: undefined,
});

interface OwnProps extends IKPITreeContext {
  metricId: number;
  children: any;
}

export const KPITreeContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    toDate,
    datetimeAttribute,
    onAddInputKPI,
    onRemoveInputKPI,
    onKPIClicked,
    onViewKPIGroup,
    children,
  } = props;

  const contextValue = useMemo(
    () => ({
      toDate,
      datetimeAttribute,
      onAddInputKPI,
      onRemoveInputKPI,
      onKPIClicked,
      onViewKPIGroup,
    }),
    [toDate, datetimeAttribute, onAddInputKPI, onRemoveInputKPI, onKPIClicked, onViewKPIGroup]
  );

  return <KPITreeContext.Provider value={contextValue}>{children}</KPITreeContext.Provider>;
};
