import {action, OnSuccessActionHook} from 'front-core';
import {InputMetricDTO} from '../../objects/dto/metric-tree.dto';

export enum MetricTreeActionType {
  ADD_INPUT_METRIC = '@@metric-tree/ADD_INPUT_METRIC',
  REMOVE_INPUT_METRIC = '@@metric-tree/REMOVE_INPUT_METRIC',
}

export const addInputMetric = (data: InputMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricTreeActionType.ADD_INPUT_METRIC, data, {onSuccess});

export const removeInputMetric = (data: InputMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricTreeActionType.REMOVE_INPUT_METRIC, data, {onSuccess});
