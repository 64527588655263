import {Funnel} from 'src/objects/models/funnel.model';
import {FunnelPageTab} from '../funnel-page.types';
import {FunnelPageDefinitionTab} from './funnel-page-definition-tab/funnel-page-definition-tab.component';

type FunnelPageTabsProps = {
  selectedTab: FunnelPageTab;
  funnel: Funnel;
};

export const FunnelPageTabs = (props: FunnelPageTabsProps) => {
  const {selectedTab, funnel} = props;

  if (selectedTab === FunnelPageTab.DEFINITION) {
    return <FunnelPageDefinitionTab funnel={funnel} />;
  }

  if (selectedTab === FunnelPageTab.RCA) {
    return <div>TO DO RCA</div>;
  }

  if (selectedTab === FunnelPageTab.PERFORMANCE) {
    return <div>TO DO PERFORMANCE</div>;
  }

  return null;
};
