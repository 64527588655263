import * as React from 'react';
import classes from './kpi-edge.module.scss';
import {BaseEdge, Edge, EdgeLabelRenderer, EdgeProps, getBezierPath} from '@xyflow/react';
import {exists, number2k} from 'front-core';
import {useMemo} from 'react';
import {colorAlphaTransformer} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

export type KPIEdgeProps = {
  correlation: number;
};

interface OwnProps extends EdgeProps<Edge<KPIEdgeProps>> {}

const STYLE = {
  strokeWidth: 3,
};

const MAX_GOOD_COLOR = '#01C366';
const MAX_BAD_COLOR = '#F13E3E';
const GRAY_COLOR = 'rgba(141, 148, 174, 1)';

const correlationToAlpha = (correlation: number) => {
  return Math.min(1, Math.max(0.4, Math.abs(correlation)));
};

export const KPIEdge: React.FC<OwnProps> = React.memo((props: OwnProps) => {
  const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data} = props;
  const {correlation} = data;
  const {t} = useTranslation();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const color = useMemo(() => {
    const alpha = correlationToAlpha(correlation);
    if (correlation === 0) {
      return GRAY_COLOR;
    }
    if (correlation < 0) {
      return colorAlphaTransformer(MAX_BAD_COLOR, alpha, true);
    }
    if (correlation > 0) {
      return colorAlphaTransformer(MAX_GOOD_COLOR, alpha, true);
    }
  }, [correlation]);
  const style = useMemo(() => ({...STYLE, stroke: color}), [color]);

  return (
    <>
      <BaseEdge id={id} {...props} path={edgePath} style={style} />
      {exists(correlation) && (
        <EdgeLabelRenderer>
          <Tooltip
            title={t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_HELPER_TEXT)}
            placement={'top'}
          >
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                background: color,
                color: Math.abs(correlation) > 0.7 ? '#fff' : '#000',
              }}
              className={classes.KPIEdge}
            >
              {`${correlation > 0 ? '+' : ''}${number2k(correlation)}`}
            </div>
          </Tooltip>
        </EdgeLabelRenderer>
      )}
    </>
  );
});
