import {useCallback, useContext, useMemo} from 'react';
import {number2k, withStopPropagation} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Funnel} from '../../../../objects/models/funnel.model';
import {ModelPageHeader} from '../../../../modules/shared/components/layout/model-page-header/model-page-header.component';
import {ModelPageHeaderShortDescription} from '../../../../modules/shared/components/layout/model-page-header/model-page-header-short-description.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {FUNNEL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {Action, Subject} from '../../../../constants/permissions';

interface OwnProps {
  funnel: Funnel;
}

type AllProps = OwnProps;

export const FunnelPageHeader: React.FC<AllProps> = (props: AllProps) => {
  const {funnel} = props;
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const value = useMemo(() => {
    let v = Math.random();
    // if (ModelSeriesGranularity.WEEK in funnel.valueAvg) {
    //   v = funnel.valueAvg[ModelSeriesGranularity.WEEK];
    // } else {
    //   v = values(funnel.valueAvg)[0];
    // }
    // return funnel.valueType === FunnelValueType.PERCENTAGE ? `${number2k(v * 100)}%` : number2k(v);
    return `${number2k(v * 100)}%`;
  }, []);

  const onCreateEdit = useCallback(() => {
    openPrimaryPanel(
      PanelKey.FUNNEL_FORM_PANEL,
      {
        [FUNNEL_ID_PATH_PARAM]: funnel?.id,
        ...funnel,
      },
      PanelType.MODAL
    );
  }, [openPrimaryPanel, funnel]);

  const {can} = usePermissions();
  const hasPermissionToEditFunnel = can(Subject.FUNNEL, Action.EDIT);

  const handleEdit =
    hasPermissionToEditFunnel && (withStopPropagation(() => onCreateEdit()) as () => void);

  return (
    <ModelPageHeader
      title={funnel.name}
      value={value}
      onEdit={handleEdit}
      valueDescription={t(TransKeys.FUNNEL_PAGE.HEADER.VALUE_DESCRIPTION)}
      subtitleItems={[
        <ModelPageHeaderShortDescription
          placeholder={t(TransKeys.FUNNEL_PAGE.HEADER.SHORT_DESCRIPTION_PLACEHOLDER)}
          description={funnel.shortDescription}
        />,
        <span>
          {t(TransKeys.FUNNEL_PAGE.HEADER.BOUNDING_DAYS, {
            boundingDays: funnel.defaultBoundingDays,
          })}
        </span>,
      ]}
    />
  );
};
