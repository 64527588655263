import {DocumentTitleWrapper, NetworkWiredRegularIcon} from 'ui-components';
import {KPITree} from '../../../../../metrics/components/kpi-tree/kpi-tree.component';
import * as React from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {SeriesDatetimeAttribute} from '../../../../../../objects/models/model-sample-series.model';

interface OwnProps {
  metricId: number;
  analyzedDate?: string;
  className?: string;
}

type AllProps = OwnProps;

export const AppKPITreeViewer: React.FC<AllProps> = (props: AllProps) => {
  const {metricId, analyzedDate, className} = props;
  const {t} = useTranslation();

  const toDate = useMemo(
    () => moment(analyzedDate, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
    [analyzedDate]
  );

  return (
    <DocumentTitleWrapper
      title={t(TransKeys.KPI_TREE.TITLE)}
      icon={NetworkWiredRegularIcon}
      className={className}
    >
      <KPITree
        metricId={metricId}
        toDate={toDate}
        showHeader={false}
        datetimeAttribute={SeriesDatetimeAttribute.DATETIME}
      />
    </DocumentTitleWrapper>
  );
};
