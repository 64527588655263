import * as React from 'react';
import type {NodeProps} from '@xyflow/react/dist/esm/types';
import classes from './kpi-group.module.scss';
import {Handle, Position} from '@xyflow/react';
import {HANDLE_STYLE} from '../kpi-tree.consts';
import {NetworkWiredRegularIcon} from 'ui-components';
import {useContext} from 'react';
import {KPITreeContext} from '../kpi-tree.context';

interface OwnProps extends NodeProps {
  data: {
    count: number;
    rootMetricId: number;
  };
}

type AllProps = OwnProps;
// @ts-ignore
const HandleComponent = Handle as any;

export const KPIGroup: React.FC<AllProps> = React.memo((props: AllProps) => {
  const {data} = props;
  const {count, rootMetricId} = data;
  const {onViewKPIGroup} = useContext(KPITreeContext);

  return (
    <div className={classes.KPIGroup} onClick={() => onViewKPIGroup(rootMetricId)}>
      <HandleComponent
        type="source"
        position={Position.Top}
        isConnectable={false}
        id={`input`}
        style={{
          left: '50%',
          ...HANDLE_STYLE,
        }}
      />
      <div className={classes.KPIGroupCount}>
        <NetworkWiredRegularIcon />
        {count} {count === 1 ? 'KPI' : 'KPIs'}
      </div>
    </div>
  );
});
