import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {MetricTreeActionType} from './metric-tree.actions';
import {modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {metricToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {
  addInputMetricNetworkRequest,
  removeInputMetricNetworkRequest,
} from '../../http/metric-tree.network-requests';

export const addInputMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.ADD_INPUT_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.ADD_INPUT_METRIC,
    request: addInputMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.INPUT_METRIC_ADDED, {
        id: payload.metricId,
      }),
      modelUpdated({id: payload.metricId}, ModelKey.INPUT_METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const removeInputMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.REMOVE_INPUT_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REMOVE_INPUT_METRIC,
    request: removeInputMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.INPUT_METRIC_REMOVED, {
        id: payload.metricId,
      }),
      modelUpdated({id: payload.metricId}, ModelKey.INPUT_METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);
