import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './funnels-main.module.scss';
import {queryFilter, tableEntityFilterGenerator} from '../../../../constants/filters';
import {funnelToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {getFunnelsNetworkRequest} from '../../../../http/funnels.network-requests';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {Funnel} from '../../../../objects/models/funnel.model';
import {
  AnalysisFileIcon,
  Button,
  ChartPyramidDuotoneIcon,
  CopyIcon,
  EditIcon,
  HoverHelperTip,
  IconButton,
  MoreIcon,
  RadarLightIcon,
  TrashIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {useDispatch, useSelector} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {isNumber, keys} from 'lodash';
import {deleteFunnelConfirmed} from '../../../../store/funnels/funnels.actions';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {generateLastModified} from '../../../../utils/history.utils';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {withStopPropagation} from '../../../../utils/general.utils';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {IntegrationStatus} from '../../../../objects/models/product-data.model';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {getListCount} from '../../../../store/store.selectors';
import {useHiddenFlag} from '../../../../core/hooks/use-hidden-flag.hook';
import {resampleModelConfirmed} from '../../../../store/model-samples/model-samples.actions';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `FUNNELS_MAIN/FUNNELS`;

export const FunnelsMain: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const {can} = usePermissions();
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();
  const user = useCurrentUser();
  const {productEntities, productEntitiesMap, integrationStatus} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const showHidden = useHiddenFlag();
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.FUNNELS.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getFunnelsNetworkRequest,
      onError: err => [funnelToastCreator('GET_ERROR')],
      modelKey: ModelKey.FUNNEL,
    }),
    []
  );
  const showFunnel = useCallback(
    (funnel: Funnel) => {
      const parameters = {[FUNNEL_ID_PATH_PARAM]: funnel.id};
      const brokenStepSignal = funnel.steps.find(
        s => s.signalValidationStatus === ValidationStatus.ERROR
      );
      if (brokenStepSignal) {
        if (!isDemoProduct) {
          parameters['initialTab'] = QuerySqlTabsNames.SQL;
        }
        parameters[SIGNAL_ID_PATH_PARAM] = brokenStepSignal.id;
      }
      openPrimaryPanel(PanelKey.VIEW_FUNNEL_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onDelete = useCallback(
    (funnel: Funnel) => dispatch(deleteFunnelConfirmed(funnel.id)),
    [dispatch]
  );
  const onRunAnalysis = useCallback(
    (funnel: Funnel) =>
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.FUNNEL_ANALYSIS,
        parameters: {
          funnel: funnel.id,
          entity: funnel.entity,
        },
      }),
    [openPrimaryPanel]
  );
  const onCreateEdit = useCallback(
    (funnel?: Funnel, parameters: any = {}) => {
      openPrimaryPanel(
        PanelKey.FUNNEL_FORM_PANEL,
        {
          [FUNNEL_ID_PATH_PARAM]: funnel?.id,
          ...parameters,
        },
        PanelType.MODAL
      );
      if (!funnel) {
        notify(AmplitudeEvent.FUNNEL_CREATE_CLICKED, {
          userId: user.id,
        });
      }
      if (funnel && parameters.cloneMode) {
        notify(AmplitudeEvent.FUNNEL_DUPLICATE_CLICKED, {
          userId: user.id,
        });
      }
      if (funnel && !parameters.cloneMode) {
        notify(AmplitudeEvent.FUNNEL_EDIT_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, notify, user]
  );
  const onResample = useCallback(
    (funnel: Funnel) => {
      dispatch(
        resampleModelConfirmed({
          modelId: funnel.id,
          modelType: ModelSampleSeriesModel.FUNNEL,
        })
      );
    },
    [dispatch]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (funnel: Funnel) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <Title className={classes.Title} text={funnel.name} caps={false} />
            {funnel.shortDescription && <HoverHelperTip title={funnel.shortDescription} />}
            {funnel.steps.some(s => s.signalValidationStatus === ValidationStatus.ERROR) && (
              <InvalidSignalWarning
                title={t(TransKeys.FUNNELS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </FlexHorizontal>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        sortable: true,
        width: '12rem',
        hidden: keys(productEntitiesMap).length < 2,
        render: (funnel: Funnel) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              className={classes.Entity}
              titleClassName={classes.Entity}
              text={productEntitiesMap[funnel.entity].name}
              icon={getEntityIcon(funnel.entity)}
            />
          </FlexHorizontal>
        ),
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED_AT),
        sortable: true,
        width: '16rem',
        render: (funnel: Funnel) => {
          const modifier = generateLastModified(funnel.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(funnel.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        sticky: 'right',
        align: 'right',
        render: (funnel: Funnel) => (
          <div className={classes.Actions}>
            <Permission subject={Subject.FUNNEL} action={Action.EDIT}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                onClick={withStopPropagation(() => onCreateEdit(funnel))}
              />
            </Permission>
            <Permission subject={Subject.FUNNEL} action={Action.CREATE}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                icon={CopyIcon}
                onClick={withStopPropagation(() => onCreateEdit(funnel, {cloneMode: true}))}
              />
            </Permission>
            <ModelActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'run-analysis',
                  hide: integrationStatus !== IntegrationStatus.ACTIVE,
                  title: t(TransKeys.FUNNELS.ACTIONS.RUN_ANALYSIS),
                  onClick: () => onRunAnalysis(funnel),
                  icon: AnalysisFileIcon,
                  showEnabled: true,
                },
                {
                  key: 'resample',
                  hide: !showHidden,
                  title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                  onClick: () => onResample(funnel),
                  icon: RadarLightIcon,
                },
                {
                  key: 'delete',
                  hide: !can(Subject.FUNNEL, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(funnel),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [
      t,
      can,
      onCreateEdit,
      onDelete,
      productEntitiesMap,
      integrationStatus,
      onRunAnalysis,
      onResample,
      showHidden,
    ]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FUNNEL) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, config]);

  return (
    <MainTableStructure
      className={classes.FunnelsMain}
      onRowClicked={funnel => showFunnel(funnel)}
      helperTextTitle={t(TransKeys.FUNNELS.HEADER.HELPER_TEXT)}
      title={title}
      icon={ChartPyramidDuotoneIcon}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      headerRenderRight={
        <Permission subject={Subject.FUNNEL} action={Action.CREATE}>
          <Button onClick={() => onCreateEdit()}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_FUNNEL)}
          </Button>
        </Permission>
      }
      emptyStateTranslationPath={TransKeys.FUNNELS.EMPTY_STATE}
      itemsPerPage={50}
    />
  );
};
