import * as React from 'react';
import {useContext, useMemo} from 'react';
import classes from './kpi-box.module.scss';
import {MetricValueType} from '../../../../../objects/models/metric.model';
import {
  ActionsDropdown,
  ArrowUpRightFromSquareRegularIcon,
  MoreIcon,
  TooltipIfOverflow,
  TrashIcon,
  TrendChip,
} from 'ui-components';
import {
  ModelSample,
  ModelSeriesGranularity,
} from '../../../../../objects/models/model-sample-series.model';
import {AnomalyMode} from '../../../../../objects/models/homepage.model';
import {getSampleWoWChange} from '../../../../homepage/components/homepage-summary/homepage-summary.utils';
import {Handle, Position} from '@xyflow/react';
import type {NodeProps} from '@xyflow/react/dist/esm/types';
import {range} from 'lodash';
import classNames from 'classnames';
import {formatMetricValue} from '../kpi-tree.utils';
import {KPIBoxPopover} from './kpi-box-popover/kpi-box-popover.component';
import {KPITreeContext} from '../kpi-tree.context';
import {HANDLE_STYLE} from '../kpi-tree.consts';

export type InputKPIProps = {
  id: number;
  name: string;
  signalId: number;
  valueType: MetricValueType;
  granularity: ModelSeriesGranularity;
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  samples: ModelSample[];
  isRoot: boolean;
  numberOfChildren: number;
};

interface OwnProps extends NodeProps {
  data: InputKPIProps;
}

const calculateAnchorPosition = (numberOfChildren: number, idx: number) => {
  const offset = 100 / numberOfChildren / 2;
  const position = (idx / numberOfChildren) * 100 + offset;
  return `${position}%`;
};

// @ts-ignore
const HandleComponent = Handle as any;

export const KPIBox: React.FC<OwnProps> = React.memo((props: OwnProps) => {
  const {data} = props;
  const {id, name, valueType, granularity, samples = [], isRoot, numberOfChildren} = data;
  const {datetimeAttribute, onRemoveInputKPI, onKPIClicked} = useContext(KPITreeContext);

  const isPercentageValue = valueType === MetricValueType.PERCENTAGE;
  const nonPartialSamples = useMemo(() => samples.filter(s => !s.isPartial), [samples]);
  const lastSample = useMemo(() => {
    if (nonPartialSamples.length === 0) {
      return;
    }
    return nonPartialSamples[nonPartialSamples.length - 1];
  }, [nonPartialSamples]);
  const previousSample = useMemo(() => {
    if (nonPartialSamples.length < 2) {
      return;
    }
    if (granularity === ModelSeriesGranularity.DAY) {
      return nonPartialSamples[nonPartialSamples.length - 8];
    }
    return nonPartialSamples[nonPartialSamples.length - 2];
  }, [nonPartialSamples, granularity]);
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, previousSample),
    [lastSample, previousSample]
  );

  const renderBottomHandles = () => {
    if (isRoot) {
      return range(numberOfChildren).map((_, idx) => (
        <HandleComponent
          key={idx}
          type="target"
          position={Position.Bottom}
          isConnectable={false}
          id={`child-${idx}`}
          style={{
            left: calculateAnchorPosition(numberOfChildren, idx),
            ...HANDLE_STYLE,
          }}
        />
      ));
    }
    if (numberOfChildren > 0) {
      return (
        <HandleComponent
          type="target"
          position={Position.Bottom}
          isConnectable={false}
          id={`input`}
          style={{
            left: '50%',
            ...HANDLE_STYLE,
          }}
        />
      );
    }
    return null;
  };

  return (
    <KPIBoxPopover
      metricId={id}
      name={name}
      isPercentageValue={isPercentageValue}
      granularity={granularity}
      lastSample={lastSample}
      previousSample={previousSample}
      datetimeAttribute={datetimeAttribute}
    >
      <div className={classes.KPIBox}>
        {!isRoot && (
          <HandleComponent
            type="source"
            id="top"
            isConnectable={false}
            position={Position.Top}
            style={HANDLE_STYLE}
          />
        )}
        {renderBottomHandles()}
        <ActionsDropdown
          dropdownClassName={classes.ActionsDropdown}
          buttonComponent={({onClick, isOpen}) => (
            <MoreIcon
              onClick={onClick}
              className={classNames(classes.Options, isOpen && classes.Open)}
            />
          )}
          iconDropdown
          actions={[{title: 'Remove', onClick: () => onRemoveInputKPI(id), icon: TrashIcon}]}
        />
        <div className={classes.Info}>
          <div className={classes.Value}>
            {lastSample?.value ? formatMetricValue(lastSample.value, isPercentageValue) : '-'}
          </div>
          {trend && (
            <TrendChip
              value={trend.value}
              isSignificant={trend.isSignificant}
              size={'small'}
              className={classes.Trend}
            />
          )}
        </div>
        <div
          className={classNames(classes.KPIName, isRoot && classes.Root)}
          onClick={isRoot ? undefined : () => onKPIClicked(id)}
        >
          <TooltipIfOverflow title={name}>
            <span className={classes.Name}>{name}</span>
          </TooltipIfOverflow>
          {!isRoot && <ArrowUpRightFromSquareRegularIcon className={classes.Icon} />}
        </div>
      </div>
    </KPIBoxPopover>
  );
});

KPIBox.defaultProps = {};
