import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-tree.module.scss';
import {
  ModelSeriesGranularity,
  SeriesDatetimeAttribute,
} from '../../../../objects/models/model-sample-series.model';
import {ConnectionLineType, Panel, ReactFlow, useEdgesState, useNodesState} from '@xyflow/react';
import {Node} from '@xyflow/react/dist/esm/types/nodes';
import {createdEdges, getLayoutedElements, toNodes, toRootNode} from './kpi-tree.utils';
import {
  DEFAULT_GRANULARITY,
  DISABLE_REACT_FLOW_INTERACTION_PROPS,
  edgeTypes,
  HIDE_REACT_FLOW_ATTRIBUTION,
  nodeTypes,
} from './kpi-tree.consts';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {AppRoutes} from '../../../../constants/app-routes';
import {KPITreeContext, KPITreeContextProvider} from './kpi-tree.context';
import {MetricSelector} from '../metric-selector/metric-selector.component';
import {
  Button,
  FancyHeader,
  ModalLayout,
  NetworkWiredRegularIcon,
  PopoverWrapper,
  useMountState,
  useRemoteSourceStated,
} from 'ui-components';
import {MetricTree} from '../../../../objects/models/metric-tree.model';
import {getMetricTreeNetworkRequest} from '../../../../http/metric-tree.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {addInputMetric, removeInputMetric} from '../../../../store/metric-tree/metric-tree.actions';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {Modal} from '@material-ui/core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';

interface BaseKPITreeProps {
  metricId: number;
  granularity?: ModelSeriesGranularity;
  showAdd?: boolean;
  showHeader?: boolean;
  className?: string;
}

interface KPITreeProps extends BaseKPITreeProps {
  openTreeModalHandler?: (metricId: number) => void;
  toDate?: string;
  datetimeAttribute?: SeriesDatetimeAttribute;
}

interface KPITreeControllerProps extends BaseKPITreeProps {
  metricTree: MetricTree;
}

const MAX_NUMBER_OF_CHILDREN = 4;

const KPITreeController: React.FC<KPITreeControllerProps> = (props: KPITreeControllerProps) => {
  const {metricTree, granularity = DEFAULT_GRANULARITY} = props;
  const {onAddInputKPI: onAddInputKPIFromContext} = useContext(KPITreeContext);
  const {t} = useTranslation();
  const isMounted = useMountState();
  const {isDemoProduct} = useDemoProduct();
  const isAdmin = useIsAdmin();
  const selectorRef = useRef<any>(null);
  const flowRef = useRef<any>(null);
  const excludeMetricIds = useMemo(
    () => [metricTree.root.id, ...metricTree.inputMetrics.map(k => k.id)],
    [metricTree]
  );
  const addDisabled = useMemo(() => {
    if (metricTree.root.numberOfChildren >= MAX_NUMBER_OF_CHILDREN) {
      return true;
    }
    return isDemoProduct && !isAdmin;
  }, [metricTree, isDemoProduct, isAdmin]);
  const initialNodes: Node[] = useMemo(
    () => [
      toRootNode(metricTree.root, granularity),
      ...toNodes(metricTree.inputMetrics, granularity),
    ],
    [metricTree, granularity]
  );
  const initialEdges = useMemo(() => createdEdges(metricTree), [metricTree]);
  const {nodes: layoutedNodes, edges: layoutedEdges} = useMemo(
    () => getLayoutedElements(initialNodes, initialEdges),
    [initialNodes, initialEdges]
  );
  const [nodes, setNodes] = useNodesState(layoutedNodes);
  const [edges, setEdges] = useEdgesState(layoutedEdges);

  const onAddInputKPI = useCallback(
    (metricId: number) => {
      onAddInputKPIFromContext(metricId);
      selectorRef.current?.close();
    },
    [onAddInputKPIFromContext]
  );

  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [layoutedNodes, layoutedEdges, setNodes, setEdges]);
  useEffect(() => {
    if (!flowRef.current) {
      return;
    }
    setTimeout(() => {
      isMounted.current &&
        flowRef.current.fitView({
          duration: 100,
        });
    }, 100);
  }, [nodes, edges, isMounted]);

  return (
    <ReactFlow
      onInit={instance => (flowRef.current = instance)}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      connectionLineType={ConnectionLineType.SmoothStep}
      maxZoom={1}
      fitView
      {...DISABLE_REACT_FLOW_INTERACTION_PROPS}
      {...HIDE_REACT_FLOW_ATTRIBUTION}
    >
      <Panel position="top-right">
        <PopoverWrapper
          ref={selectorRef}
          buttonRenderer={({onClick}) => (
            <Button
              onClick={onClick}
              variant={'outlined'}
              disabled={addDisabled}
              helperText={
                metricTree.root.numberOfChildren >= MAX_NUMBER_OF_CHILDREN
                  ? t(TransKeys.KPI_TREE.MAX_METRICS_HELPER)
                  : undefined
              }
            >
              Add input KPI
            </Button>
          )}
        >
          <MetricSelector
            onChange={metricId => onAddInputKPI(metricId)}
            excludeIds={excludeMetricIds as any}
          />
        </PopoverWrapper>
      </Panel>
    </ReactFlow>
  );
};

export const KPITree: React.FC<KPITreeProps> = (props: KPITreeProps) => {
  const {
    metricId,
    openTreeModalHandler,
    showHeader = true,
    toDate,
    datetimeAttribute,
    className,
  } = props;
  const {t} = useTranslation();
  const [modalMetricId, setModalMetricId] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const {source: metricTree, exec: getMetricTree} = useRemoteSourceStated({
    networkRequest: getMetricTreeNetworkRequest,
  });
  const allRelevantMetricIds = useMemo(() => {
    if (!metricTree) {
      return [];
    }
    return [metricTree.root.id, ...metricTree.inputMetrics.map(im => im.id)];
  }, [metricTree]);
  const onKPIClicked = useCallback(
    metricId => history.push(AppRoutes.viewMetric(metricId)),
    [history]
  );
  const onAddInputKPI = useCallback(
    (inputMetricId: number) =>
      dispatch(
        addInputMetric({
          metricId: metricId,
          inputMetricId,
        })
      ),
    [dispatch, metricId]
  );
  const onRemoveInputKPI = useCallback(
    (inputMetricId: number) =>
      dispatch(
        removeInputMetric({
          metricId: metricId,
          inputMetricId,
        })
      ),
    [dispatch, metricId]
  );
  const onViewKPIGroup = useCallback(
    (metricId: number) => {
      if (openTreeModalHandler) {
        openTreeModalHandler(metricId);
        return;
      }
      setModalMetricId(metricId);
    },
    [openTreeModalHandler, setModalMetricId]
  );
  useEffect(() => {
    getMetricTree(metricId, {toDate, datetimeAttribute});
  }, [getMetricTree, metricId, toDate, datetimeAttribute]);
  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.INPUT_METRIC && allRelevantMetricIds.indexOf(data.id) > -1) {
        getMetricTree(metricId);
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getMetricTree, allRelevantMetricIds, metricId]);

  return (
    <>
      <div className={classNames(classes.KPITree, className)} key={metricId}>
        {showHeader && (
          <div className={classes.Header}>
            <div className={classes.Title}>{t(TransKeys.KPI_TREE.TITLE)}</div>
          </div>
        )}
        <div className={classes.Content}>
          {!metricTree && <GenericLoading />}
          {metricTree && (
            <KPITreeContextProvider
              metricId={metricId}
              onAddInputKPI={onAddInputKPI}
              onRemoveInputKPI={onRemoveInputKPI}
              onKPIClicked={onKPIClicked}
              onViewKPIGroup={onViewKPIGroup}
              datetimeAttribute={datetimeAttribute}
              toDate={toDate}
            >
              <KPITreeController {...props} metricTree={metricTree} />
            </KPITreeContextProvider>
          )}
        </div>
      </div>
      {modalMetricId && (
        <Modal
          onClick={e => e.stopPropagation()}
          className={classes.ModalWrapper}
          open={modalMetricId}
          onClose={() => setModalMetricId(undefined)}
        >
          <ModalLayout className={classes.TreeWrapper}>
            <FancyHeader title={t(TransKeys.KPI_TREE.TITLE)} icon={NetworkWiredRegularIcon} />
            <KPITree
              metricId={modalMetricId}
              openTreeModalHandler={onViewKPIGroup}
              showHeader={false}
              className={classes.Large}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};
