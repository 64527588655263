import * as React from 'react';
import classNames from 'classnames';
import {
  QuickRunAnalysesType,
  QuickRunForAdmins,
} from '../../../../../objects/models/analysis.model';
import classes from './quick-run-parameters-step.module.scss';
import {useCallback, useContext, useMemo} from 'react';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {capitalize, values} from 'lodash';
import {PanelKey} from '../../../../../constants/panels';
import {SIGNAL_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {TableEntityBinding} from '../../../../../objects/models/table.model';
import {Button, DatePickerInput, LabelWrapper, Select, TextInput} from 'ui-components';
import {MetricForSignalSmartSelector} from '../../../../shared/core/smart-selector/metric-for-signal-smart-selector.component';
import {exists} from 'front-core';
import {EntitySelector} from '../../../analysis-forms/components/ui-selectors/entity-selector/entity-selector.component';
import {AnalysisSelectorVariant} from '../../../analysis-forms/components/ui-selectors/analysis-selector.types';
import {PopulationSegmentQueryBuilder} from '../../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import {FormStep} from '../../../../shared/components/layout/form-step/form-step.component';
import {preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {sharedClasses} from '../../../../shared';
import yup from '../../../../../config/yup.config';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {
  startEndDatesValidator,
  validateSignalBoundingDays,
  validateSignalTimeframeBoundingFor,
} from '../../../analysis-forms/analysis-parameters/shared-validators';

interface OwnProps {
  data: QuickRunForAdmins;
  onSubmit: (data: QuickRunForAdmins) => void;
  className?: string;
}

const PRELIMINARY_PERIOD_RELEVANT_TYPES = [
  QuickRunAnalysesType.PAYMENT_RETENTION,
  QuickRunAnalysesType.ACTIVE_RETENTION,
  QuickRunAnalysesType.CONVERSION_TO_PAID,
  QuickRunAnalysesType.CONVERSION_TO_TRIAL,
  QuickRunAnalysesType.TRIAL_TO_PAID,
];

const MAX_DATE = new Date();

type AllProps = OwnProps;

const quickRunAnalysesValidator = yup.object().shape({
  runType: yup.string().oneOf(values(QuickRunAnalysesType), 'Required').required(),
  signalId: yup.number().when('runType', {
    is: i => [QuickRunAnalysesType.ALL_FUNNELS, QuickRunAnalysesType.ALL_CONTENTS].includes(i),
    then: yup.number().nullable(),
    otherwise: yup.number().required(),
  }),
  preliminaryPeriod: yup
    .number()
    .nullable()
    .optional()
    .test(
      validateSignalBoundingDays({
        signalIdParameter: 'signalId',
        optional: true,
      })
    ),
  entity: yup.string().required(),
  populationFilter: queryElementValidatorFactory(false),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalIdParameter: 'signalId',
      parameterName: 'KPI',
      parametersPrefix: null,
    })
  ),
});

export const QuickRunParametersStep: React.FC<AllProps> = (props: AllProps) => {
  const {data, onSubmit, className} = props;
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {defaultSource, productEntities, numberOfEntities} = useProductData();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: data,
    resolver: yupResolver(quickRunAnalysesValidator),
  });

  const runType = watch('runType');
  const entity = watch('entity');
  const lastValidDate = useMemo(
    () =>
      defaultSource?.lastValidDate
        ? moment(defaultSource?.lastValidDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
        : undefined,
    [defaultSource]
  );
  const actualMaxDate = useMemo(
    () => (lastValidDate ? lastValidDate.toDate() : MAX_DATE),
    [lastValidDate]
  );
  const options = useMemo(() => {
    return values(QuickRunAnalysesType).map((type: string) => ({
      value: type,
      label: capitalize(type.replace(/_/g, ' ')).replace(/aov/gi, 'AOV'),
    }));
  }, []);
  const onChangeType = useCallback(
    type => {
      setValue('signalId', null);
      setValue('runType', type);
    },
    [setValue]
  );
  const onSetDates = useCallback(
    (monthsCount: number) => {
      const endDate = lastValidDate ? lastValidDate : moment().startOf('day');
      const startDate = endDate.clone().subtract(monthsCount, 'months');
      setValue('runParameters.start_date', startDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT));
      setValue('runParameters.end_date', endDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT));
    },
    [setValue, lastValidDate]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const renderPrimarySelection = () => {
    const sharedFilters = {
      entityContext: entity,
      entityBinding: TableEntityBinding.DEFAULT,
      with_population_filter: true,
    };
    const sharedParameters = {
      clearable: false,
      placeholder: 'Select',
      onSignalInfo,
    };
    switch (runType) {
      case QuickRunAnalysesType.PAYMENT_RETENTION:
        return (
          <Controller
            render={({field, fieldState: {error}}) => (
              <LabelWrapper
                label={'Select KPI'}
                error={Boolean(error)}
                helperText={error?.message}
                className={classes.LabelWrapper}
              >
                <MetricForSignalSmartSelector
                  {...sharedParameters}
                  error={Boolean(error)}
                  value={field.value}
                  onChange={field.onChange}
                  filters={{
                    templates: ['payment_retention'],
                    ...sharedFilters,
                  }}
                />
              </LabelWrapper>
            )}
            name={'signalId'}
            control={control}
          />
        );
      case QuickRunAnalysesType.ACTIVE_RETENTION:
        return (
          <Controller
            render={({field, fieldState: {error}}) => (
              <LabelWrapper
                label={'Select KPI'}
                error={Boolean(error)}
                helperText={error?.message}
                className={classes.LabelWrapper}
              >
                <MetricForSignalSmartSelector
                  {...sharedParameters}
                  error={Boolean(error)}
                  value={field.value}
                  onChange={field.onChange}
                  filters={{
                    templates: ['bounded_actions_ts'],
                    ...sharedFilters,
                  }}
                />
              </LabelWrapper>
            )}
            name={'signalId'}
            control={control}
          />
        );
      case QuickRunAnalysesType.CONVERSION_TO_PAID:
      case QuickRunAnalysesType.CONVERSION_TO_TRIAL:
      case QuickRunAnalysesType.TRIAL_TO_PAID:
        return (
          <Controller
            render={({field, fieldState: {error}}) => (
              <LabelWrapper
                label={'Select KPI'}
                error={Boolean(error)}
                helperText={error?.message}
                className={classes.LabelWrapper}
              >
                <MetricForSignalSmartSelector
                  {...sharedParameters}
                  error={Boolean(error)}
                  value={field.value}
                  onChange={field.onChange}
                  filters={{
                    templates: ['bounded_action_ts'],
                    ...sharedFilters,
                  }}
                />
              </LabelWrapper>
            )}
            name={'signalId'}
            control={control}
          />
        );
      // case QuickRunAnalysesType.REVENUE_AOV:
      //   return (
      //     <Controller
      //       render={({field, fieldState: {error}}) => (
      //         <LabelWrapper
      //           label={'Select KPI'}
      //           error={Boolean(error)}
      //           helperText={error?.message}
      //           className={classes.LabelWrapper}
      //         >
      //           <MetricForSignalSmartSelector
      //             {...sharedParameters}
      //             error={Boolean(error)}
      //             value={field.value}
      //             onChange={field.onChange}
      //           />
      //         </LabelWrapper>
      //       )}
      //       name={'signalId'}
      //       control={control}
      //     />
      //   );
    }
  };
  const renderMaxDaysAfterReference = () => {
    if (!exists(runType)) {
      return null;
    }
    if (PRELIMINARY_PERIOD_RELEVANT_TYPES.indexOf(runType) === -1) {
      return null;
    }
    return (
      <Controller
        render={({field, fieldState: {error}}) => (
          <LabelWrapper
            label={'Preliminary period (optional)'}
            error={Boolean(error)}
            helperText={error?.message}
            className={classes.LabelWrapper}
          >
            <div className={classes.MaxDaysAfterReferenceContainer}>
              <TextInput
                value={field.value}
                onChange={field.onChange}
                error={Boolean(error)}
                placeholder={'#'}
                fullWidth={false}
                className={classes.NumberInput}
                type={'number'}
              />
              <span className={classes.Suffix}>Days</span>
            </div>
          </LabelWrapper>
        )}
        name={'preliminaryPeriod'}
        control={control}
      />
    );
  };

  return (
    <FormStep footer={<Button onClick={handleSubmit(onSubmit)}>Next</Button>}>
      <div className={classNames(classes.QuickRunParametersStep, className)}>
        <form onKeyDown={preventSubmitOnEnter} className={sharedClasses.NoOverflow}>
          <div className={classes.QuickRunAnalysesPanel}>
            {numberOfEntities > 1 && (
              <Controller
                render={({field}) => (
                  <LabelWrapper
                    error={Boolean(errors?.entity)}
                    label={'Entity'}
                    className={classes.LabelWrapper}
                  >
                    <EntitySelector
                      value={{entity: field.value}}
                      onChange={p => field.onChange(p.entity)}
                      productEntities={productEntities}
                      variant={AnalysisSelectorVariant.INLINE}
                    />
                  </LabelWrapper>
                )}
                name={'entity'}
                control={control}
              />
            )}
            <LabelWrapper
              label={'Type'}
              error={Boolean(errors.runType)}
              helperText={errors?.runType?.message}
              className={classes.LabelWrapper}
            >
              <Select
                options={{options}}
                value={runType}
                onChange={onChangeType}
                error={Boolean(errors.runType)}
                clearable={false}
                capitalize={false}
                sortValues={false}
              />
            </LabelWrapper>
            {renderPrimarySelection()}
            {renderMaxDaysAfterReference()}
            <LabelWrapper
              label={'Timeframe'}
              className={classes.LabelWrapper}
              error={Boolean(errors?.runParameters?.start_date)}
              helperText={errors?.runParameters?.start_date?.message}
              renderRight={
                <div className={classes.AutoSetDates}>
                  <span onClick={() => onSetDates(3)} className={classes.InlineButton}>
                    Last 3 month
                  </span>
                  <span onClick={() => onSetDates(6)} className={classes.InlineButton}>
                    Last 6 month
                  </span>
                </div>
              }
            >
              <div className={classes.DateRange}>
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <DatePickerInput
                      onChange={field.onChange}
                      value={field.value}
                      placeholder={'Start date'}
                      error={Boolean(error)}
                      className={classes.StartDate}
                      maxDate={actualMaxDate}
                      dateFormat={'DD/MM/YYYY'}
                      dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
                    />
                  )}
                  name={'runParameters.start_date'}
                  control={control}
                />
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <DatePickerInput
                      onChange={field.onChange}
                      value={field.value}
                      placeholder={'End date'}
                      error={Boolean(error)}
                      maxDate={actualMaxDate}
                      dateFormat={'DD/MM/YYYY'}
                      dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
                    />
                  )}
                  name={'runParameters.end_date'}
                  control={control}
                />
              </div>
              {lastValidDate && (
                <div className={classes.LastValidDate}>
                  Last valid date: {lastValidDate.format('DD/MM/YYYY')}
                </div>
              )}
            </LabelWrapper>
            <Controller
              render={({field, fieldState: {error}}) => (
                <LabelWrapper
                  label={'Population filter'}
                  className={classes.LabelWrapper}
                  error={Boolean(errors?.populationFilter)}
                >
                  <PopulationSegmentQueryBuilder
                    query={field.value as any}
                    onChange={field.onChange}
                    errors={error}
                    entityContext={entity}
                  />
                </LabelWrapper>
              )}
              name={'populationFilter'}
              control={control}
            />
          </div>
        </form>
      </div>
    </FormStep>
  );
};
