import {useCallback, useContext, useMemo, useState} from 'react';
import {composition} from 'front-core';
import classes from './app-navigator.module.scss';
import {User} from '../../../../objects/models/user.model';
import {Popover} from '@material-ui/core';
import {AppUserModal} from '../app-user-modal/app-user-modal.component';
import {sharedClasses} from 'src/modules/shared';
import {AppRoutes} from '../../../../constants/app-routes';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {IntegrationStatus} from '../../../../objects/models/product-data.model';
import UserMenuLauncher from './components/user-menu-launcher.component';
import AppNavigatorLayout from './components/app-navigator-layout.component';
import AppNavigatorLink from './components/app-navigator-link.component';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {AppNavigatorContext} from '../../../../core/contexts/app-navigator.context';
import OnboardingLauncher from './components/onboarding-launcher.component';
import {Chip, ChipVariant} from 'ui-components';
import * as React from 'react';
import {AppNotifications} from '../app-notifications/app-notifications.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {Team} from '../../../../objects/models/team.model';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  user: User;
  teams: Team[];
  onLogout: () => void;
  onScopeChange: (productScopeId: number) => void;
  onChangeDefaultTeam: (defaultTeamId: number) => void;
  links: AppNavigatorLinkItem[];
  additionalLinks?: AppNavigatorLinkItem[];
  adminPrivileges: boolean;
  onChangeAdminPrivileges: (value: boolean) => void;
  scope: number;
  defaultTeamId: number;
  disabled?: boolean;
}

export interface AppNavigatorLinkItem {
  icon: any;
  label: string;
  route: string;
  tooltipText?: string;
  disabled?: boolean;
  count?: number;
  overlayText?: string;
  hidden?: boolean;
}

type AllProps = OwnProps;

const AppNavigatorComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    user,
    teams,
    links: linksFromProps = [],
    additionalLinks,
    scope,
    defaultTeamId,
    onScopeChange,
    onChangeDefaultTeam,
    onLogout,
    adminPrivileges,
    onChangeAdminPrivileges,
    disabled: disabledFromProps,
  } = props;
  const isAdmin = useIsAdmin();
  const showGenAIChatBtn = useFeatureIsOn(FeatureFlag.GEN_AI_CHAT as string);
  const showPusherNotifications = useFeatureIsOn(FeatureFlag.PUSHER_NOTIFICATIONS as string);
  const {integrationStatus} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {isCollapsed, toggleCollapse} = useContext(AppNavigatorContext);
  const [userModelAnchorEl, setUserModelAnchorEl] = useState(null);
  const onShowUserModal = e => setUserModelAnchorEl(e.currentTarget);
  const product = useMemo(() => user.products.find(p => p.id === scope), [user.products, scope]);

  const links = useMemo(
    () =>
      linksFromProps
        .filter(l => !l.hidden)
        .map(l => {
          const disabled =
            disabledFromProps ||
            l.disabled ||
            (integrationStatus === IntegrationStatus.SETUP && !isAdmin);
          return {...l, disabled};
        }),
    [linksFromProps, disabledFromProps, integrationStatus, isAdmin]
  );
  const onInvite = useCallback(() => {
    openPrimaryPanel(PanelKey.INVITE_USERS_PANEL, {}, PanelType.MODAL);
    notify(AmplitudeEvent.INVITE_USERS_CLICKED);
  }, [openPrimaryPanel, notify]);

  const onAskAI = useCallback(() => {
    openPrimaryPanel(PanelKey.AI_INTERACTION_ANALYSIS_PANEL);
  }, [openPrimaryPanel]);

  const renderAdditionalLinks = () =>
    additionalLinks.map((l, idx) => <AppNavigatorLink {...l} key={idx} collapsed={isCollapsed} />);

  const renderUserModalPopover = () => (
    <Popover
      classes={{
        paper: sharedClasses.BlankPaper,
      }}
      open={Boolean(userModelAnchorEl)}
      anchorEl={userModelAnchorEl}
      onClose={() => setUserModelAnchorEl(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <AppUserModal
        className={classes.Popover}
        scopeProductId={scope}
        defaultTeamId={defaultTeamId}
        onScopeChange={onScopeChange}
        onChangeDefaultTeam={onChangeDefaultTeam}
        onLogout={onLogout}
        onInvite={onInvite}
        user={user}
        teams={teams}
        adminPrivileges={adminPrivileges}
        onChangeAdminPrivileges={onChangeAdminPrivileges}
      />
    </Popover>
  );

  if (integrationStatus === IntegrationStatus.SETUP) {
    return (
      <>
        <AppNavigatorLayout
          links={links}
          rootLink={AppRoutes.dmp}
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
          renderBottom={() => (
            <>
              {renderAdditionalLinks()}
              {isAdmin && showPusherNotifications && <AppNotifications />}
              <UserMenuLauncher
                user={user}
                product={product}
                isCollapsed={isCollapsed}
                isActive={Boolean(userModelAnchorEl)}
                onClick={onShowUserModal}
              />
            </>
          )}
        />
        {renderUserModalPopover()}
      </>
    );
  }

  return (
    <>
      <AppNavigatorLayout
        links={links}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        renderBottom={() => (
          <div className={classes.Links}>
            {showGenAIChatBtn && (
              <div onClick={onAskAI} className={classes.AskButton}>
                {!isCollapsed && (
                  <>
                    Ask AI{' '}
                    <Chip
                      label={'BETA'}
                      variant={ChipVariant.NEW}
                      size={'xsmall'}
                      className={classes.Chip}
                    />
                  </>
                )}
                {isCollapsed && (
                  <>
                    <span>AI</span>
                    <span className={classes.Beta}>BETA</span>
                  </>
                )}
              </div>
            )}
            {!isDemoProduct && <OnboardingLauncher />}
            {showPusherNotifications && <AppNotifications />}
            {renderAdditionalLinks()}
            <UserMenuLauncher
              user={user}
              product={product}
              isActive={Boolean(userModelAnchorEl)}
              isCollapsed={isCollapsed}
              onClick={onShowUserModal}
            />
          </div>
        )}
      />
      {renderUserModalPopover()}
    </>
  );
};

const AppNavigator = composition<OwnProps>(AppNavigatorComponent);

export default AppNavigator;
