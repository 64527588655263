import dagre from 'dagre';
import {MetricSeries} from '../../../../objects/models/metric-page.model';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {Node} from '@xyflow/react/dist/esm/types/nodes';
import {entries} from 'lodash';
import {
  DEFAULT_GRANULARITY,
  EdgeType,
  KPI_GROUP_NODE_HEIGHT,
  KPI_GROUP_NODE_WIDTH,
  KPI_NODE_HEIGHT,
  KPI_NODE_WIDTH,
  NodeType,
} from './kpi-tree.consts';
import {exists, number2k} from 'front-core';
import {InputMetric, MetricTree} from '../../../../objects/models/metric-tree.model';

export const generateIdForMetric = (metricId: number) => `metric-${metricId}`;
export const generateIdForMetricGroup = (metricId: number) => `metric-group-of-${metricId}`;

export const getSeries = (series: MetricSeries[], granularity = DEFAULT_GRANULARITY) => {
  return series.find(s => s.granularity === granularity) || series[0];
};

const sharedMetricData = (metric: InputMetric, granularity?: ModelSeriesGranularity) => ({
  id: metric.id,
  name: metric.name,
  signalId: metric.signalId,
  valueType: metric.valueType,
  granularity: getSeries(metric.series, granularity)?.granularity,
  samples: getSeries(metric.series, granularity)?.samples || [],
  numberOfChildren: metric.numberOfChildren || 0,
});

export const toRootNode = (metric: InputMetric, granularity?: ModelSeriesGranularity): Node => {
  return {
    id: generateIdForMetric(metric.id),
    type: NodeType.METRIC,
    position: {x: 0, y: 0},
    data: {
      ...sharedMetricData(metric as any, granularity),
      numberOfChildren: metric.numberOfChildren || 0,
      isRoot: true,
    },
  };
};

export const toNode = (metric: InputMetric, granularity?: ModelSeriesGranularity): Node => {
  return {
    id: generateIdForMetric(metric.id),
    type: NodeType.METRIC,
    position: {x: 0, y: 0},
    data: {
      ...sharedMetricData(metric, granularity),
      isRoot: false,
    },
  };
};

export const toNodes = (metrics: InputMetric[], granularity?: ModelSeriesGranularity): Node[] => {
  const nodes: Node[] = [];
  for (const m of metrics) {
    nodes.push(toNode(m, granularity));
    if (m.numberOfChildren > 0) {
      nodes.push({
        id: generateIdForMetricGroup(m.id),
        type: NodeType.METRIC_GROUP,
        position: {x: 0, y: 0},
        data: {
          rootMetricId: m.id,
          count: m.numberOfChildren,
        },
      });
    }
  }
  return nodes;
};

export const createdEdges = (tree: MetricTree) => {
  const edges = [];
  for (const [idx, m] of entries(tree.inputMetrics)) {
    edges.push({
      id: `edge-${tree.root.id}-${m.id}`,
      source: generateIdForMetric(m.id),
      target: generateIdForMetric(tree.root.id),
      type: EdgeType.METRIC_CONNECTION,
      targetHandle: `child-${idx}`,
      animated: true,
      data: {
        correlation: m.correlation,
      },
      style: {
        strokeWidth: 3,
      },
    });
    if (m.numberOfChildren > 0) {
      edges.push({
        id: `edge-${tree.root.id}-${m.id}-group`,
        source: generateIdForMetricGroup(m.id),
        target: generateIdForMetric(m.id),
        animated: true,
        style: {
          strokeWidth: 2,
        },
      });
    }
  }
  return edges;
};

const getNodeDimensions = (node: Node) => {
  if (node.type === NodeType.METRIC) {
    return {width: KPI_NODE_WIDTH, height: KPI_NODE_HEIGHT};
  }
  if (node.type === NodeType.METRIC_GROUP) {
    return {width: KPI_GROUP_NODE_WIDTH, height: KPI_GROUP_NODE_HEIGHT};
  }
};

// reference: https://reactflow.dev/learn/layouting/layouting#dagre
export const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({rankdir: 'TB'});
  nodes.forEach(node => {
    dagreGraph.setNode(node.id, getNodeDimensions(node));
  });
  edges.forEach(edge => {
    dagreGraph.setEdge(edge.target, edge.source);
  });
  dagre.layout(dagreGraph);

  const newNodes = nodes.map(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const dimensions = getNodeDimensions(node);
    const newNode = {
      ...node,
      targetPosition: 'top',
      sourcePosition: 'bottom',
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - dimensions.width / 2,
        y: nodeWithPosition.y - dimensions.height / 2,
      },
    };

    return newNode;
  });

  return {nodes: newNodes, edges};
};

export const formatMetricValue = function (value: number, isPercentageValue: boolean) {
  if (!exists(value)) {
    return '-';
  }
  if (isPercentageValue) {
    return number2k(value * 100) + '%';
  }
  return number2k(value);
};
